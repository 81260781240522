import React from 'react';
import Styleclass from './Configurator.module.css';

function Configurator({ isToggle, loadDesigns, multiModelDesignInfo }) {


    return (
        <div>
            <div className={isToggle && Styleclass.toggledContainer} >

                {multiModelDesignInfo.length > 0 && multiModelDesignInfo.map((option) => {

                    return (

                        <div key={option.id} onClick={() => loadDesigns(option.shortId)} className={isToggle ? Styleclass.colorContainerToggle : Styleclass.colorContainer}>
                            {option.displayType =='thumbnail' && option.thumbnail !== '' ? 
                                <img className={isToggle ? Styleclass.colorToggle : Styleclass.color} src={option.thumbnail} />
                                :
                                <button className={isToggle ? Styleclass.colorToggle : Styleclass.color} style={{ backgroundColor: option.color }} />
                            }
                            <label className={isToggle ? Styleclass.btntextToggle : Styleclass.btntext}>{option.name}</label>
                        </div>
                    )
                })}
            </div>

        </div>
    );
}

export default Configurator;

import React from 'react';
import ogmoLogo from './ogmoLogo.png';
import Styleclass from './QRcode.module.css';
import QRCode from 'react-qr-code';

function QRCodePopup({ setShowQRCode,shortId }) {
    const viewerUrl = `${process.env.REACT_APP_VIEWER_URL}${shortId}`;

    return (
        <>
            <div className={Styleclass.screen}>
                <div className={Styleclass.container}>
                    <div className={Styleclass.box1}>
                        <img className={Styleclass.logo} src={ogmoLogo} alt="ogmo logo" />
                        <div style={{ display: 'flex', justifyContent: 'center',marginTop:'30px',marginBottom:'30px'}}>
                            <QRCode value={viewerUrl} size={150}/>
                        </div>
                        <p className={Styleclass.artext}> Works in ARKit supported iOS devices and ARCore supported Android devices</p>
                    </div>
                    <div className={Styleclass.box2}>
                        <div className={Styleclass.closebtn} onClick={() => setShowQRCode(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className={Styleclass.closeIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className={Styleclass.textDiv}>
                            <div>
                                <div className={Styleclass.title}>You are just one step away from the magic!</div>
                            </div>
                            <div>
                                <div className={Styleclass.description}>Scan the QR code from your smartphone or tablet for Augmented Reality experience.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default QRCodePopup;
import React from "react";
import LoaderElement from './LoaderElement';

const PageLoader = () => {
  return (
    <div style={{width: '40px', height: '40px'}}>
      <LoaderElement/>
    </div>
  );
}

export default PageLoader;
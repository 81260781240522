import React from "react";
import { LoaderDot, LoaderBg } from './LoaderAsssets';
import Styleclass from './LoaderStyle.module.css';

const LoaderElement = () => {

  return (
    <div className={Styleclass.loaderHolder}>
      <div className={`${Styleclass.imgHolder} ${Styleclass.zoomInOut}`}><LoaderDot/></div>
      <div className={Styleclass.imgHolder}><LoaderBg/></div>
    </div>
  );
}

export default LoaderElement;
import React, { useState, useEffect } from 'react'
import './App.css';
import PageLoader from "./components/PageLoader/PageLoader";
import * as api from "../src/services/api";
import { OGMOCanvas, useEnvironmentStore, useSceneStore } from 'ogmo-renderer'
import ARButton from './components/ARButton/ARButton';
import QRCodePopup from './components/QRcode/QRCodePopup';
import Styleclass from './components/ARButton/ARButton.module.css';
import StyleclassConfigurator from './components/Configurator/Configurator.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Slide} from 'react-toastify';
import Configurator from './components/Configurator/Configurator';
import { Fade as Hamburger } from 'hamburger-react'
const urlParams = window.location.pathname.split("/");

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
	  width,
	  height
	};
  }

function App() {

	const [isInProgress, setIsInProgress] = useState(false);
	const [errorInDesignId, setErrorInDesignId] = useState(false);
	const [usdzPath,setUsdzPath] = useState('');
	const [showQRCode, setShowQRCode] = useState(false);
	const [designShortId,setDesignShortId] = useState('');
	const [isToggle, setIsToggle] = useState(false);
	const [isMultiModelDesign,setIsMultiModelDesign] = useState(false);
	const [multiModelDesignInfo,setMultiModelDesignInfo] = useState([]);
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	const modelPath = useSceneStore((state) => state.modelPath);
	const setEnvironmentMap = useEnvironmentStore((state) => state.setEnvironmentMap);
	const setEnvironmentMapExplicitPath = useEnvironmentStore((state) => state.setEnvironmentMapExplicitPath);
	const setBackgroundColor = useEnvironmentStore((state) => state.setBackgroundColor);
	const setAmbientIntensity = useEnvironmentStore((state) => state.setAmbientIntensity);
	const setShadowOpacity = useEnvironmentStore((state) => state.setShadowOpacity);
	const setShadowBlur = useEnvironmentStore((state) => state.setShadowBlur);
	const setShadowBias = useEnvironmentStore((state) => state.setShadowBias);
	const setModelPath = useSceneStore((state) => state.setModelPath);
	const setCameraPosition = useSceneStore((state) => state.setCameraPosition);
	const resetSceneDimensions = useSceneStore((state) => state.resetSceneDimensions);

	useEffect(() => {
		const shortId = urlParams[1];
		loadDesigns(shortId);
	}, []);

	async function loadDesigns(shortId) {
		setModelPath(null);
		resetSceneDimensions();
		if (shortId !== "") {
			setIsInProgress(true);
			setDesignShortId(shortId)
			await api.get(`designs/shortid/${shortId}`)
				.then(data => {
					if(data.data.isMultiModelDesign){
						setIsMultiModelDesign(data.data.isMultiModelDesign)
						setMultiModelDesignInfo(data.data.singleModelDesigns)
						data.data.singleModelDesigns.map((defaultDesign) => {
								if(defaultDesign.id===1){
									loadDesigns(defaultDesign.shortId)
								}
							})
					}
					else {
						setUsdzPath(data.data['sceneInfo']['modelPathUsdz'])
						setModelPath(data.data['sceneInfo']['modelPath']);
						setEnvironmentMap(data.data['sceneInfo']['environmentMapId']);
						setEnvironmentMapExplicitPath(data.data['sceneInfo']['environmentMapPath']);
						setBackgroundColor(data.data['sceneInfo']['backgroundColor']);
						setAmbientIntensity(data.data['sceneInfo']['ambientIntensity']);
						setShadowOpacity(data.data['sceneInfo']['shadowOpacity']);
						setShadowBlur(data.data['sceneInfo']['shadowBlur']);
						setShadowBias(data.data['sceneInfo']['shadowBias']);
						setCameraPosition(data.data['sceneInfo']['cameraPosition']);
						setIsInProgress(false);
					}
				})
				.catch(error => {
					setIsInProgress(false);
					setErrorInDesignId(true);
					openToast();
					console.log(error);
				});
		} else {
			setErrorInDesignId(true);
			openToast();
		}
	};
	
	useEffect(() => {
		if(windowDimensions.width>=768){
		setIsToggle(false)	
		}
		
	}, [windowDimensions.width])

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	function openToast(){
		toast.error('Error Loading Design', {
			position: "bottom-center",
			autoClose: 6000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			transition:Slide,
		});
    }

		if (errorInDesignId) {
			return (
				<div style={{width:'100vw',height:"100vh",display:"flex",alignItems:'center',justifyContent:'center'}}>
					<PageLoader />
					<ToastContainer position="bottom-center" autoClose={6000} hideProgressBar newestOnTop={false}
									closeOnClick rtl={false} pauseOnFocusLoss draggable={false} pauseOnHover={false}
									closeButton={false} transition={Slide}
					/>
				</div>
			);
		} else {
			return (
				<div id="ogmo-canvas-holder" style={{width:'100vw',height:"100vh",display:"flex",flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
					<div style={{width:'100vw',height:"100vh"}}>
						{!isInProgress ?
							<OGMOCanvas />
							:
							<div style={{ width: '100vw', height: "100vh", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
								<PageLoader />
							</div>
						}
					</div>
					{showQRCode &&
          				<QRCodePopup setShowQRCode={setShowQRCode} shortId={designShortId}/>
        			}
					
					<div className={isToggle ? Styleclass.containerToggle : isMultiModelDesign ? Styleclass.containerMulti : Styleclass.container}>
						<ARButton isToggle={isToggle} glbPath={modelPath} usdzPath={usdzPath} setShowQRCode={setShowQRCode}/>
					</div>
					{isMultiModelDesign &&
						<div className={isToggle ? StyleclassConfigurator.containerToggle : StyleclassConfigurator.container}>
							<Configurator isToggle={isToggle} loadDesigns={loadDesigns} setDesignShortId={setDesignShortId} setUsdzPath={setUsdzPath} setIsInProgress={setIsInProgress} multiModelDesignInfo={multiModelDesignInfo} />
						</div>
		            }
					{isMultiModelDesign &&
						<div className={StyleclassConfigurator.hamburger}>
							<Hamburger size={32} toggled={isToggle}  toggle={setIsToggle} />
						</div>
					}
					</div>
			);
		}



}

export default App;

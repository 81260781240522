import React from 'react';
import ArLogo from './ARIcon.png';
import Styleclass from './ARButton.module.css';

function ARButton({glbPath,usdzPath,setShowQRCode,isToggle}) {

    let platform = window.navigator.platform;
    let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    let os = null;

    if (/iPhone/g.test(navigator.userAgent)) {
        os = 'iphone';
    }
    else if (/iPad/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
        os = 'ipad';
    }
    else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'windows';
    }
    else if (/android/i.test(navigator.userAgent)) {
        os = 'android';
    }
    else {
        os = 'device';
    }

    let fallback_url = "";


    return (
        <div>
            {(os === 'android') && <div style={{display:'flex',justifyContent:'center'}}>
                    <button className={isToggle ? Styleclass.arbuttonToggle : Styleclass.arbutton}>
                        <a className={Styleclass.atag} href={`intent://arvr.google.com/scene-viewer/1.0?file=${glbPath}&mode=ar_only#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${fallback_url};end;`}>
                            <img className={Styleclass.btnlogo} src={ArLogo} alt="ar logo"/>
                            <label className={isToggle ? Styleclass.btntextToggle : Styleclass.btntext}>Try it yourself!</label>
                        </a>
                    </button>
                </div>
            }

            {(os === 'iphone' || os === 'ipad') && <div style={{display:'flex',justifyContent:'center'}}>
                <button className={isToggle ? Styleclass.arbuttonToggle : Styleclass.arbutton}>
                    <a className={Styleclass.atag} href={usdzPath} rel="ar">
                        <img className={Styleclass.btnlogo} src={ArLogo} alt="ar logo"/>
                        <label className={isToggle ? Styleclass.btntextToggle : Styleclass.btntext}>Try it yourself!</label>
                    </a>
                </button>
            </div>
            }

            {(os === 'windows' || os === 'device') && <div style={{display:'flex',justifyContent:'center'}}>
                <button className={isToggle ? Styleclass.arbuttonToggle : Styleclass.arbutton} onClick={() => setShowQRCode(true)}>
                    <div className={Styleclass.atag}>
                        <img className={Styleclass.btnlogo} src={ArLogo} alt="ar logo"/>
                        <label className={isToggle ? Styleclass.btntextToggle : Styleclass.btntext}>Try it yourself!</label>
                    </div>
                </button>
            </div>
            }
        </div>
    );
}

export default ARButton;

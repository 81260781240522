const APIEndpoint =  process.env.REACT_APP_API_ENDPOINT;

const is_response_ok = response => (response / 100 === 2);

export const get =  async(url, signal = null, headers = {}) => {
  
  // url = APIEndpoint + url;
  url = url.startsWith('http') ? url : APIEndpoint + url;
  return new Promise( async(resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: headers,
      signal: signal
    })
    .then(async response => {
      const respose_status = response.status;
      response = await response.json();
      if (is_response_ok(respose_status)) resolve(response);
      reject(response);
    });
  });
}
